if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !location.pathname.includes('download-app.html')) {
  window.location = "http://m.qifeizn.com/"
}

let time = null
$('.qf-message').click(function(){
  $('.qf-message-alert').remove()
  let alert = $(this).attr('alert')
  let el =  $(`<div class="qf-message-alert">${alert}</div>`)
  el.css({
    'position': 'fixed',
    'top': '55%',
    'left': '50%',
    'z-index': '99999',
    'padding': '10px',
    'box-shadow': '0 0 10px rgba(0,0,0,.2)',
    'background-color': '#fff',
    'border-radius': '5px'
  })

  $('body').append(el)

  time = setTimeout(() => {
    el.remove()
  }, 2000)
})